import axios from 'axios';
import { mockPaymentChannels } from '../../mocks/mockPayments';
import { PaymentTransactions, TransactionTypeEnum, PaymentType, AvailablePaymentChannels, SearchList, OrderStatus, FeeCalculation, BreadCrumbDetails } from '../../../models/Payment';
import { Dispatch } from 'react';
import store from "../../../redux/store";
import _ from 'lodash';

import { Order } from '../../../models/Payment';
import { ReportType } from '../../../models/Reports';
import { ClientBanking } from '../../../models/Client';
import { formatDynamicSearchParameters } from '../../../utils/advanceSearchParameters';
import { User } from '../../../models/User';
import { dynamicQueryUrls } from '../../../utils/dynamicQueryReportUrls';

export const GET_TRANSACTIONS_REQUEST = 'GET_TRANSACTIONS_REQUEST';
export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS';
export const GET_TRANSACTIONS_FAILURE = 'GET_TRANSACTIONS_FAILURE';

export const GET_ECHECK_TRANSACTIONS_REQUEST = 'GET_ECHECK_TRANSACTIONS_REQUEST';
export const GET_ECHECK_TRANSACTIONS_SUCCESS = 'GET_ECHECK_TRANSACTIONS_SUCCESS';
export const GET_ECHECK_TRANSACTIONS_FAILURE = 'GET_ECHECK_TRANSACTIONS_FAILURE';

export const GET_TRANSACTIONS_DETAIL_REQUEST = 'GET_TRANSACTIONS_DETAIL_REQUEST';
export const GET_TRANSACTIONS_DETAIL_SUCCESS = 'GET_TRANSACTIONS_DETAIL_SUCCESS';
export const GET_TRANSACTIONS_DETAIL_FAILURE = 'GET_TRANSACTIONS_DETAIL_FAILURE';
export const GET_CONVENIENCE_FEE_REQUEST = 'GET_CONVENIENCE_FEE_REQUEST';
export const GET_CONVENIENCE_FEE_SUCCESS = 'GET_CONVENIENCE_FEE_SUCCESS';
export const GET_CONVENIENCE_FEE_FAILURE = 'GET_CONVENIENCE_FEE_FAILURE';
export const GET_PAYMENT_REQUEST = 'GET_PAYMENT_REQUEST';
export const GET_PAYMENT_SUCCESS = 'GET_PAYMENT_SUCCESS';
export const GET_PAYMENT_FAILURE = 'GET_PAYMENT_FAILURE';
export const GET_AVAILABLE_PAYMENT_CHANNELS = 'GET_AVAILABLE_PAYMENT_CHANNELS';
export const GET_AVAILABLE_PAYMENT_CHANNELS_SUCCESS = 'GET_AVAILABLE_PAYMENT_CHANNELS_SUCCESS';
export const SET_SEARCH_FIELDS = 'SET_SEARCH_FIELDS';
export const CLEAR_FEES = 'CLEAR_FEES';
export const RESET_PAYMENTS_STORE_REQUEST = 'RESET_PAYMENTS_STORE_REQUEST';
export const RESET_PAYMENTS_STORE_SUCCESS = 'RESET_PAYMENTS_STORE_SUCCESS';
export const CANCEL_STEP = 'CANCEL_STEP';
export const GET_PDFDOWNLOAD_URL = "GET_PDFDOWNLOAD_URL";
export const GET_PDFDOWNLOAD_URL_SUCCESS = "GET_PDFDOWNLOAD_URL_SUCCESS";
export const GET_PDFDOWNLOAD_URL_FAILURE = "GET_PDFDOWNLOAD_URL_FAILURE";
export const GET_CSVDOWNLOAD_URL = "GET_CSVDOWNLOAD_URL";
export const GET_CSVDOWNLOAD_URL_SUCCESS = "GET_CSVDOWNLOAD_URL_SUCCESS";
export const GET_CSVDOWNLOAD_URL_FAILURE = "GET_CSVDOWNLOAD_URL_FAILURE";
export const RESET_ACTION_RESULT = "RESET_ACTION_RESULT";
export const GET_CLIENT_BANKING_REQUEST = 'GET_CLIENT_BANKING_REQUEST';
export const GET_CLIENT_BANKING_SUCCESS = 'GET_CLIENT_BANKING_SUCCESS';
export const GET_CLIENT_BANKING_FAILURE = 'GET_CLIENT_BANKING_FAILURE';
export const CANCEL_FETCH_TRANSACTIONS = 'CANCEL_FETCH_TRANSACTIONS';

export const getTransactionsAction = (
    page: number,
    searchFields: SearchList,
    actionToken: string,
    currentUser?: User,
    transactionType?: TransactionTypeEnum,
    reportType?: ReportType,
    extraTransactionType?: TransactionTypeEnum
) => async (dispatch: Dispatch<any>, getState: () => any) => {
    const { cancelTokenSource } = getState().paymentTransactions;
    if (cancelTokenSource && typeof cancelTokenSource.cancel === 'function') {
        cancelTokenSource.cancel('Operation canceled due to new request.');
    }

    const source = axios.CancelToken.source();

    dispatch({
        type: GET_TRANSACTIONS_REQUEST,
        payload: {
            actionToken,
            cancelToken: source
        }
    });

    try {
        let results: any = {
            page,
            totalRecords: 0,
            data: []
        };

        let baseApiUrl = getState().webAppSettings.baseApiUrl;
        const getControllerName = (fields: any) => {
            if (fields.itemReferenceNumber) return 'OrderLines';
            if (fields.orderIdentifier || fields.departmentId) return 'OrderSummaries';
            if (fields.nameOnCard || fields.lastFourOnCard) return 'OrderPayments';
            return 'OrderTransactions';
        };

        const controllerName = getControllerName(searchFields);

        let urlSchema = `/MSB_Order/api/v1/${controllerName}/dynamicQuery?`;
        const searchValues = _.pickBy(searchFields, value => value);
        const url = dynamicQueryUrls(baseApiUrl, urlSchema, reportType, transactionType, extraTransactionType, searchValues);

        const searchParams = url.searchParams;

        if (!searchValues?.paymentType) {
            searchParams.set('paymentType', 'CreditCard|eCheck|PayPal|WireTransfer');
        }

        Object.entries(searchValues).map(([key, value]: any) => {
            const param = formatDynamicSearchParameters(key, value, currentUser, searchValues);
            if (param != '') {
                if (key === 'paymentType') {
                    searchParams.set(
                        'paymentType',
                        !value ? 'CreditCard|eCheck|PayPal|WireTransfer' : value.includes('|') ? value : `${value}`
                    );
                } else {
                    searchParams.set(param, value);
                }
            }
        });

        url.search = searchParams.toString().replace("paymentType=", "paymentType:or=");

        const newUrl = decodeURIComponent(url.toString()).replace(/\+/g, ' ');

        const response = await axios.get(newUrl, { cancelToken: source.token });

        const enums = {
            transactionType: TransactionTypeEnum,
            paymentType: PaymentType,
            orderStatus: OrderStatus
        } as any;

        const params = new URLSearchParams(new URL(newUrl).search);

        const matchesQuery = (item: any) =>
            Array.from(params.entries()).every(([key, val]) => {
                const actualKey = key.replace(":or", "").replace(":from", "").replace(":xor", "").replace(":like", "");

                if (controllerName === 'OrderLines' && ['itemReferenceNumber', 'createdAt'].includes(actualKey)) {
                    return true;
                }

                if (controllerName === 'OrderSummaries' && ['orderIdentifier', 'departmentId', 'createdAt'].includes(actualKey)) {
                    return true;
                }

                if (controllerName === 'OrderPayments' && ['nameOnCard', 'lastFourOnCard', 'createdAt'].includes(actualKey)) {
                    return true;
                }

                const values = val.split("|");
                const enumValues = enums[actualKey];

                if (item[actualKey] === undefined) {
                    return false;
                }
                let minTotalAmount, maxTotalAmount;
                if (actualKey === "totalAmount") {
                    if (key.includes(":from")) minTotalAmount = parseFloat(values[0]);
                    if (key.includes(":to")) maxTotalAmount = parseFloat(values[0]);

                    const itemTotalAmount = parseFloat(item[actualKey]);
                    if (isNaN(itemTotalAmount)) return false;

                    return (
                        (minTotalAmount === undefined || itemTotalAmount >= minTotalAmount) &&
                        (maxTotalAmount === undefined || itemTotalAmount <= maxTotalAmount)
                    );
                }

                return values.some((v: any) => {
                    const itemValue = String(item[actualKey]).toLowerCase();
                    const queryValue = v.toLowerCase();
                    const enumName = enumValues ? enumValues[Number(v)] : undefined;

                    return (
                        itemValue.includes(queryValue) ||
                        (enumName && itemValue === enumName.toLowerCase())
                    );
                });
            });

        console.log(response)

        results.data = controllerName === 'OrderLines'
            ? response.data.flatMap((orderLine: any) => {
                const orderTransaction = orderLine?.orderTransaction;
                if (orderTransaction && (!orderTransaction.orderLines)) {
                    orderTransaction.orderLines = [];
                }
                orderTransaction.orderLines.unshift({ ...orderLine });
                return matchesQuery(orderTransaction) ? [orderTransaction] : [];
            })
            : controllerName === 'OrderSummaries'
                ? response.data.flatMap((summary: any) => {
                    const transactions = summary?.orderTransactions || [];
                    transactions.forEach((transaction: any) => {
                        if (!transaction.orderSummary) {
                            transaction.orderSummary = { ...summary };
                        }
                    });
                    return transactions.filter(matchesQuery);
                })
                : controllerName === 'OrderPayments'
                    ? response.data.flatMap((payment: any) => {
                        const transactions = Array.isArray(payment?.orderTransaction)
                            ? payment.orderTransaction
                            : [payment.orderTransaction];

                        transactions.forEach((transaction: any) => {
                            if (!transaction.orderPayment) {
                                transaction.orderPayment = { ...payment };
                            }
                        });

                        return transactions.filter(matchesQuery);
                    })

                    : response.data;

        results.totalRecords = results.data.length;

        console.log(results)

        results.data.forEach((item: any) => {
            if (item.paymentType === "ECheck") {
                item.lastFourOnCard = item.orderPayment?.accountNumberLastFour;
            }
            if (item.paymentType === "CreditCard") {
                item.orderPayment.cardLogo += item.orderSummary?.isDebitCardTransaction ? " - Debit" : " - Credit";
            }
            if (Array.isArray(item.orderLines)) {
                const matchedLine = item.orderLines.find((lineItem: any) => lineItem.itemReferenceNumber == searchFields.itemReferenceNumber);
                const defaultLine = item.orderLines[0] || {};
                item.itemReferenceNumber = matchedLine?.itemReferenceNumber || defaultLine?.itemReferenceNumber || '';
                item.itemName = matchedLine?.itemName || defaultLine?.itemName || '';
            } else {
                console.log('No order lines found for item:', item);
            }
        });

        dispatch({
            type: GET_TRANSACTIONS_SUCCESS,
            payload: {
                results,
                downloadUrl: newUrl,
                searchFields,
                actionToken
            }
        });

    } catch (error: any) {
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
        } else {
            dispatch({
                type: GET_TRANSACTIONS_FAILURE,
                payload: {
                    error: error.message,
                    actionToken
                }
            });
        }
    }
};


export const cancelFetchTransactions = () => (dispatch: Dispatch<any>, getState: () => any) => {
    const { cancelTokenSource } = getState().paymentTransactions;

    if (cancelTokenSource && typeof cancelTokenSource.cancel === 'function') {
        cancelTokenSource.cancel('Request canceled by user.');
        dispatch({ type: CANCEL_FETCH_TRANSACTIONS });
    } else {
        console.warn('Cancel token source is invalid or missing cancel method.');
    }
};

export const getECheckPaymentUpdates =
    (searchFields: SearchList, actionToken: string, currentUser?: User, transactionType?: TransactionTypeEnum, reportType?: ReportType, extraTransactionType?: TransactionTypeEnum) =>
        (dispatch: Dispatch<any>) => {
            dispatch({
                type: GET_ECHECK_TRANSACTIONS_REQUEST,
                payload: {
                    actionToken
                }
            });

            let results: any = [];
            let url: URL;
            let search_params: any;
            results.totalRecords = 0;
            results.data = new Array<any>();

            let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
            let urlSchema = '/MSB_Echeck/api/v1/ElectronicCheckPaymentUpdates/dynamicQuery?'

            const searchValues = _.pickBy(searchFields, value => value)
            url = dynamicQueryUrls(baseApiUrl, urlSchema, reportType, transactionType, extraTransactionType, searchValues)

            let itemReferenceNumber = "";
            search_params = url.searchParams;
            {
                Object.entries(searchValues).map(([key, value]) => {
                    key === 'itemReferenceNumber'
                        ? itemReferenceNumber = `${value}`.trim()
                        : search_params.set(formatDynamicSearchParameters(key, value, currentUser), value);
                })
            }

            url.search = search_params.toString();

            let new_url = url.toString();
            axios.get(new_url)
                .then(function (response) {
                    results.data = (itemReferenceNumber) ? response.data.filter((r: any) => r.orderLines.some((s: any) => s.itemReferenceNumber === itemReferenceNumber)) : response.data;
                    results.totalRecords = results.data.length;
                    dispatch({
                        type: GET_ECHECK_TRANSACTIONS_SUCCESS,
                        payload: {
                            results,
                            searchFields,
                            actionToken
                        }
                    });
                })
                .catch(function (error) {
                    dispatch({
                        type: GET_ECHECK_TRANSACTIONS_FAILURE,
                        payload: {
                            error,
                            actionToken
                        }
                    });
                })
                .then(function () {
                });

        }

export const transactionDetailAction = (msbId: string, actionToken: string, breadCrumbDetails?: BreadCrumbDetails) => (dispatch: Dispatch<any>) => {

    dispatch({
        type: GET_TRANSACTIONS_DETAIL_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Order/api/v1/OrderTransactions/${msbId}`;

    axios.get(_url)
        .then(function (response) {
            let transactionDetail = response.data;
            dispatch({
                type: GET_TRANSACTIONS_DETAIL_SUCCESS,
                payload: {
                    transactionDetail,
                    breadCrumbDetails,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_TRANSACTIONS_DETAIL_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        });

};

export const achTransactionDetailAction = (rowDetails: any, orderIdentifier: string, actionToken: string, breadCrumbDetails?: BreadCrumbDetails) => (dispatch: Dispatch<any>) => {

    dispatch({
        type: GET_TRANSACTIONS_DETAIL_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = `/MSB_Order/api/v1/OrderSummaries/byOrderIdentifier/${orderIdentifier}`;

    axios.get(_url)
        .then(function (response) {
            const orderTransactions = response?.data?.orderTransactions.map((orderTransaction: any) => {
                if (orderTransaction?.orderStatus === "Success") {
                    if (rowDetails?.transactionType == orderTransaction?.transactionType) return orderTransaction
                }
            }).filter(Boolean);
            const transactionDetail = orderTransactions[0]
            dispatch({
                type: GET_TRANSACTIONS_DETAIL_SUCCESS,
                payload: {
                    transactionDetail,
                    breadCrumbDetails,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_TRANSACTIONS_DETAIL_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        });

};

export const getClientBankingAction = (page: number, pageSize: number, clientBanking: any, actionToken: string, sortBy?: string, sortDirection?: string) => (dispatch: Dispatch<any>) => {

    dispatch({
        type: GET_CLIENT_BANKING_REQUEST,
        payload: {
            actionToken
        }
    });


    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let _url;
    if (sortBy && sortDirection) {
        _url = new URL(baseApiUrl + "/MSB_Client/api/v1/ClientBankAccounts?page=" + page + "&pageSize=" + pageSize + "&sortBy=" + sortBy + "&sortDirection=" + sortDirection);

    } else {
        _url = new URL(baseApiUrl + "/MSB_Client/api/v1/ClientBankAccounts?page=" + page + "&pageSize=" + pageSize);
    }
    let search_params: any;
    search_params = _url.searchParams;

    {
        Object.entries(clientBanking).map(([key, value]: any) => {
            if (key === "departmentId") {
                search_params.set(`departmentIds`, `${value}`);
            } else {
                search_params.set(`${key}`, `${value}`);
            }
        })
    }

    _url.search = search_params.toString();
    let new_url = _url.toString();

    axios.get(new_url)
        .then(function (response) {
            let clientBankingDetail = response.data;
            dispatch({
                type: GET_CLIENT_BANKING_SUCCESS,
                payload: {
                    clientBankingDetail,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_CLIENT_BANKING_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        });

}

const handleFileDownloadClick = (fileView: any) => {
    if (fileView.fileName !== "") {

        const downloadUrl = window.URL.createObjectURL(new Blob([fileView.fileData], { type: "application/pdf" }))
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.target = "_blank";
        link.setAttribute('download', fileView.fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}

export const pdfDownload = (url: string) => (dispatch: Dispatch<any>) => {

    dispatch({
        type: GET_PDFDOWNLOAD_URL,
    });

    let bearerToken = store.getState().webAppSettings.bearerToken;
    let results: any = [];

    const pdfBody = {
        url: url
    }

    const config = {
        headers: { Authorization: `Bearer ${bearerToken}` },
        responseType: 'blob'
    };

    let _url = "/MSB_Document/api/v1/Pdf"
    axios.request({ url: _url, method: 'POST', data: pdfBody, responseType: 'blob' })
        .then(function (response) {
            results = response.data;
            handleFileDownloadClick({ fileData: results, fileName: "CreateChargeback.pdf" });
            dispatch({
                type: GET_PDFDOWNLOAD_URL_SUCCESS,
                payload: {
                    results,
                }
            });

        })
        .catch(function (error) {
            dispatch({
                type: GET_PDFDOWNLOAD_URL_FAILURE,
                payload: {
                    error
                }
            });
        })
        .then(function () {
        });

};

export const csvDownload = (url: string) => (dispatch: Dispatch<any>) => {

    dispatch({
        type: GET_CSVDOWNLOAD_URL,
    });

    let bearerToken = store.getState().webAppSettings.bearerToken;
    let msbBearerToken = store.getState().auth.msbBearerToken;
    let results: any = [];

    const csvBody = {
        url: url
    }

    const config = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
            MsbBearer: `${msbBearerToken}`
        },
        responseType: 'blob'
    };

    let _url = "/MSB_Document/api/v1/Csv"
    axios.request({ url: _url, method: 'POST', data: csvBody, responseType: 'blob' })
        .then(function (response) {
            results = response.data;
            handleFileDownloadClick({ fileData: results, fileName: "CreateChargeback.csv" });
            dispatch({
                type: GET_CSVDOWNLOAD_URL_SUCCESS,
                payload: {
                    results,
                }
            });

        })
        .catch(function (error) {
            dispatch({
                type: GET_CSVDOWNLOAD_URL_FAILURE,
                payload: {
                    error
                }
            });
        })
        .then(function () {
        });

};

export const calculateConvenienceFee = (amount: number, methodName: string, orderDetails: any) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: GET_CONVENIENCE_FEE_REQUEST,
    });

    let results: any = [];
    let bearerToken = store.getState().webAppSettings.bearerToken;
    let msbBearerToken = store.getState().auth.msbBearerToken;

    const feeBody = {
        previousTransactionId: orderDetails.msbId,
        amount: amount,
        initiatedBy: orderDetails.initiatedBy,
        methodName: methodName,
        paymentType: orderDetails.paymentType,
        IsCardPresent: orderDetails?.orderPayment?.processorType == "VantivTriPos" ? true : false
    }

    const config = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
            MsbBearer: `${msbBearerToken}`,
            ClientId: orderDetails.orderPayment.clientId,
            DepartmentId: orderDetails.orderPayment.departmentId,
            PaymentChannelId: orderDetails.orderPayment.paymentChannelId
        }
    };

    let _url = "/MSB_Order/api/v1/ProcessOrder/DecrementFee"

    axios.post(_url, feeBody, config)
        .then(function (response) {
            results = response.data.requestObject;
            dispatch({
                type: GET_CONVENIENCE_FEE_SUCCESS,
                payload: {
                    results,
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_CONVENIENCE_FEE_FAILURE,
                payload: {
                    error
                }
            });
        })
        .then(function () {
        });
}

export const submitOrderAction = (order: Order, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: GET_PAYMENT_REQUEST,
        payload: {
            order,
            actionToken
        }
    });

    let _url = "/MSB_Order/api/v1/ProcessOrder/ProcessOrder";
    let bearerToken = store.getState().webAppSettings.bearerToken;
    let msbBearerToken = store.getState().auth.msbBearerToken;
    let { type, clientMsbId, departmentMsbId, paymentChannelMsbId, paymentChannelName, ...rest } = order;

    const config = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
            MsbBearer: `${msbBearerToken}`,
            ClientId: clientMsbId,
            DepartmentId: departmentMsbId,
            PaymentChannelId: paymentChannelMsbId
        }
    };

    axios.post(_url, rest, config)
        .then(function (response) {
            dispatch({
                type: GET_PAYMENT_SUCCESS,
                payload: {
                    receiptDetails: response.data.processorData,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            let errorMessage = '';
            if (error.response) {
                errorMessage = error.response.data.processorMessage;
            }
            dispatch({
                type: GET_PAYMENT_FAILURE,
                payload: {
                    error: errorMessage,
                    actionToken
                }
            });
        });

};

export const getAvailablePaymentChannelsAction = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: GET_AVAILABLE_PAYMENT_CHANNELS,
    });

    let results = [];
    let slice = mockPaymentChannels;
    for (let x = 0; x < slice.length; x++) {
        let paymentChannels = new AvailablePaymentChannels();
        paymentChannels.paymentChannelId = slice[x].paymentChannelId;
        paymentChannels.paymentChannel = slice[x].paymentChannel;
        results.push(paymentChannels)
    }
    dispatch({
        type: GET_AVAILABLE_PAYMENT_CHANNELS_SUCCESS,
        payload: {
            results
        }
    })
}


export const setSearchFieldsAction = (searchFields: SearchList) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SET_SEARCH_FIELDS,
        payload: {
            searchFields
        }
    });

};

export const clearConveninceFee = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: CLEAR_FEES,
    });
}

export const cancelAction = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: CANCEL_STEP,
    });
};

export const resetActionResult = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: RESET_ACTION_RESULT
    });
};


export const resetPaymentStoreAction = (actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: RESET_PAYMENTS_STORE_REQUEST,
        payload: {
            actionToken
        }
    });

    dispatch({
        type: RESET_PAYMENTS_STORE_SUCCESS,
        payload: {
            actionToken
        }
    });
};


import { useEffect, useState, useMemo, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import Report from './Report';
import { DateFormatter, CurrencyFormatter, ConvenienceFeeFormatter, EllipseTextFormatter } from '../../components/Formatters';
import { transactionDetailAction } from '../../redux/actions/payments/paymentTransactions';
import { setReportColumns } from '../../redux/actions/reports/report';
import { getContentUrl } from './Report';
import { IAppState } from '../../redux/storeTypes';
import { ReportType } from '../../models/Reports';
import { TransactionTypeEnum, OrderStatus } from '../../models/Payment';
import { allPaymentsFormSchema, convFeeFormSchema, paymentsFormSchema } from './components/AdvancedSearchColumns';
import _ from 'lodash';

interface IReportViewerProps {
    reportColumns: Map<ReportType, Array<any>>;
}

const rowStyle = (row: any) => ({
    backgroundColor:
        row.transactionType === 'Chargeback' ? '#E6F7FD' :
            row.transactionType === 'Void' || row.transactionType === 'Reversal' ? '#FEFFE6' :
                row.transactionType === 'Refund' || row.transactionType === 'Return' ? '#FFF2E8' :
                    '',
});

const reportConfigs = {
    authorizations: {
        reportTitle: 'Authorizations Report',
        reportType: ReportType.Authorization,
        transactionType: TransactionTypeEnum.Authorization,
        formSchema: paymentsFormSchema,
        orderStatus: OrderStatus.Success,
        rowStyle: { backgroundColor: '#FEFFE6' },
        searchSummary: true
    },
    chargebacks: {
        reportTitle: 'Chargeback Report',
        reportType: ReportType.Chargeback,
        transactionType: TransactionTypeEnum.Chargeback,
        formSchema: paymentsFormSchema,
        rowStyle: { backgroundColor: '#E6F7FD' },
        searchSummary: true
    },
    complianceExceptions: {
        reportTitle: 'Compliance Exceptions Report',
        reportType: ReportType.ComplianceExceptions,
        transactionType: TransactionTypeEnum.Unknown,
        formSchema: paymentsFormSchema,
        rowStyle: { backgroundColor: '#E6F7FD' },
    },
    convenienceFee: {
        reportTitle: 'Convenience Fee Report',
        reportType: ReportType.ConvenienceFee,
        transactionType: TransactionTypeEnum.Unknown,
        formSchema: convFeeFormSchema,
        rowStyle: { backgroundColor: '#E6F7FD' },
    },
    declined: {
        reportTitle: 'Declined Report',
        reportType: ReportType.Declined,
        transactionType: TransactionTypeEnum.Unknown,
        formSchema: paymentsFormSchema,
        orderStatus: OrderStatus.Declined,
        rowStyle: { backgroundColor: '#E6F7FD' },
        searchSummary: true
    },
    feeAudit: {
        reportTitle: 'Fee Audit Report',
        reportType: ReportType.FeeAudit,
        transactionType: TransactionTypeEnum.Unknown,
        formSchema: paymentsFormSchema,
        rowStyle: { backgroundColor: '#E6F7FD' },
    },
    noConvenienceFee: {
        reportTitle: 'No Convenience Fee Report',
        reportType: ReportType.NoConvenienceFee,
        transactionType: TransactionTypeEnum.Unknown,
        formSchema: convFeeFormSchema,
        rowStyle: { backgroundColor: '#E6F7FD' },
    },
    payments: {
        reportTitle: 'Payments Report',
        reportType: ReportType.Payment,
        transactionType: TransactionTypeEnum.Sale,
        formSchema: paymentsFormSchema,
        orderStatus: OrderStatus.Success,
        searchSummary: true
    },
    pendingpayments: {
        reportTitle: 'Pending Payments Report',
        reportType: ReportType.Pending,
        transactionType: TransactionTypeEnum.Sale,
        formSchema: paymentsFormSchema,
        orderStatus: OrderStatus.Pending,
    },
    refunds: {
        reportTitle: 'Refunds Report',
        reportType: ReportType.Refund,
        transactionType: TransactionTypeEnum.Refund,
        formSchema: paymentsFormSchema,
        orderStatus: OrderStatus.Success,
        rowStyle: { backgroundColor: '#F6FFED' },
        searchSummary: true
    },
    transactions: {
        reportTitle: 'All Transactions Report',
        reportType: ReportType.Transactions,
        transactionType: TransactionTypeEnum.Unknown,
        formSchema: allPaymentsFormSchema,
        orderStatus: OrderStatus.Success,
        rowStyle,
        searchSummary: true
    },
    voids: {
        reportTitle: 'Voids Report',
        reportType: ReportType.Void,
        transactionType: TransactionTypeEnum.Void,
        formSchema: paymentsFormSchema,
        orderStatus: OrderStatus.Success,
        rowStyle: { backgroundColor: '#FEFFE6' },
        searchSummary: true
    }

};

const usePathParam = (index: number) => {
    const location = useLocation();
    return location.pathname.split('/')[index];
};

const ReportViewer = ({ reportColumns }: IReportViewerProps) => {
    const dispatch = useDispatch();
    const reportTypeParam = usePathParam(2) || '';
    const [redirect, setRedirect] = useState<string>('');

    const viewDetailsFormatter = useCallback((cell: any, row: any) => (
        <span>
            <a onClick={() => dispatch(transactionDetailAction(row.msbId, 'ReportDetails', { reportTitle: config?.reportTitle || '', reportRoute: reportTypeParam }))}>
                View Details
            </a>
        </span>
    ), [dispatch, reportTypeParam]);

    const config = useMemo(() => {
        return reportConfigs[reportTypeParam as keyof typeof reportConfigs] || null;
    }, [reportTypeParam]) as any;

    useEffect(() => {
        if (reportColumns && !reportColumns.has(config.reportType)) {
            const commonColumns = [
                { dataField: 'msbId', text: 'ID', editable: false, sort: false, hidden: true, configurable: false },
                { dataField: 'clientId', text: 'Client', editable: false, sort: true, default: true, configurable: true },
                { dataField: 'departmentId', text: 'Department', editable: false, sort: true, default: true, configurable: true },
                { dataField: 'paymentChannelId', text: 'Payment Channel', editable: false, sort: true, default: false, configurable: true },
                { dataField: 'initiatedBy', text: 'Initiated by', editable: false, sort: true, configurable: true },
                { dataField: 'orderIdentifier', text: 'Transaction ID', editable: false, sort: true, default: true, configurable: true },
                { dataField: 'transactionType', text: 'Transaction type', editable: false, sort: true, default: true, configurable: true },
                { dataField: 'lastFourOnCard', text: 'Last four on card/account', editable: false, sort: true, default: false, configurable: true },
                { dataField: 'createdAt', text: 'Transaction date', editable: false, sort: true, default: true, formatter: DateFormatter, configurable: true },
                { dataField: 'itemReferenceNumber', text: 'Item reference', editable: false, sort: true, default: true, configurable: true },
                { dataField: 'itemName', text: 'Item name', editable: false, sort: true, default: true, configurable: true },
                { dataField: 'reason', text: 'Reason', editable: false, sort: true, default: true, configurable: true },
                { dataField: 'amount', text: 'Subtotal', editable: false, sort: true, formatter: CurrencyFormatter, configurable: true },
                { dataField: 'convenienceFee', text: 'Convenience fee', editable: false, sort: true, formatter: ConvenienceFeeFormatter, configurable: true },
                { dataField: 'totalAmount', text: 'Total amount', editable: false, sort: true, formatter: CurrencyFormatter, default: true, configurable: true },
                { dataField: 'originalAmount', text: 'Original amount', editable: false, sort: true, formatter: CurrencyFormatter, configurable: true },
                { dataField: 'remainingBalance', text: 'Remaining balance', editable: false, sort: true, formatter: CurrencyFormatter, configurable: true },
                { dataField: 'nameOnCard', text: 'Customer name', editable: false, sort: true, default: true, configurable: true },
                { dataField: 'orderPayment.cardLogo', text: 'Card type', editable: false, sort: true, default: false, configurable: true },
                { dataField: 'phone', text: 'Customer phone', editable: false, sort: true, configurable: true },
                { dataField: 'email', text: 'Customer email', editable: false, sort: true, configurable: true },
                { dataField: 'addressLine1', text: 'Address line 1', editable: false, sort: true, configurable: true },
                { dataField: 'addressLine2', text: 'Address line 2', editable: false, sort: true, configurable: true },
                { dataField: 'city', text: 'City', editable: false, sort: true, configurable: true },
                { dataField: 'state', text: 'State', editable: false, sort: true, configurable: true },
                { dataField: 'zip', text: 'Zip code', editable: false, sort: true, configurable: true },
                { dataField: '', text: 'Actions', editable: false, formatter: viewDetailsFormatter, configurable: false }
            ];

            const filterColumns = (fields: any) => commonColumns.filter(column => fields.includes(column.text));

            const reportColumnsMap = {
                Authorization: [...commonColumns],
                Chargeback: [...commonColumns],
                ComplianceExceptions: [
                    ...filterColumns(['ID', 'Client', 'Department', 'Payment Channel', 'Transaction type', 'Transaction date', 'Customer name', 'Total amount'])
                ],
                ConvenienceFee: [
                    ...filterColumns(['ID', 'Client', 'Department', 'Payment Channel', 'Transaction type', 'Transaction date', 'Customer name', 'Total amount'])
                ],
                Declined: commonColumns.map(column => ({ ...column, dataField: column.dataField === 'reason' ? 'processorMessage' : column.dataField })),
                FeeAudit: [
                    ...filterColumns(['ID', 'Client', 'Department', 'Payment Channel', 'Transaction type', 'Transaction date', 'Customer name', 'Total amount'])
                ],
                NoConvenienceFee: [
                    ...filterColumns(['ID', 'Client', 'Department', 'Payment Channel', 'Transaction type', 'Transaction date', 'Customer name', 'Total amount'])
                ],
                Payment: [...commonColumns],
                Pending: [...commonColumns],
                Refund: [...commonColumns],
                Transactions: [...commonColumns],
                Void: [...commonColumns],
            } as any;

            const selectedColumns = reportColumnsMap[config.reportType] || commonColumns;
            dispatch(setReportColumns(config.reportType, selectedColumns));
        }
    }, []);


    if (!config) return <div>Report type not found</div>;
    if (redirect) return <Redirect push to={redirect} />;

    return (
        <Report
            reportTitle={config.reportTitle}
            reportType={config.reportType}
            breadCrumbRoute={reportTypeParam}
            transactionType={config.transactionType}
            formSchema={config.formSchema}
            rowStyle={config.rowStyle}
            documentContentUrl={getContentUrl(config.orderStatus, config.transactionType)}
            searchSummary={config.searchSummary}
        />
    );
};

const mapStateToProps = (state: IAppState) => ({
    reportColumns: state.reports.reportColumns
});

export default connect(mapStateToProps)(ReportViewer);

import { AccountDDATypeEnum } from "./Client";
import { OrderPayment, OrderSummary } from "./Order";

export enum ItemType {
    Unknown = 0,
    BlindPayment = 1,
    Physical = 2,
    Tax = 3,
    Utility = 4
}

export enum PaymentType {
    Unknown = 0,
    CreditCard = 1,
    ECheck = 2,
    PayPal = 3,
    AuthorizeDotNet = 4,
    WireTransfer = 5,
    Cash = 6,
    PhysicalCheck = 7
}

export enum PaymentCardTypeEnum {
    Unknown = 0,
    AmericanExpressCredit = 1,
    AmericanExpressDebit = 2,
    DiscoverCredit = 3,
    DiscoverDebit = 4,
    MastercardCredit = 5,
    MastercardDebit = 6,
    MastercardDebitPIN = 7,
    VisaCredit = 8,
    VisaDebit = 9,
    VisaDebitPIN = 10,
    ElectronicCheck = 11
}

export class Metadata {
}

export enum PaymentMethodOnFileType {
    Unknown = 0,
    Card = 1,
    BankAccount = 2
}

export class Order {
    type: string = "";
    methodName: string = "";
    initiatedBy: string = "";

    clientMsbId: string = "";
    paymentChannelMsbId: string = "";
    paymentChannelName: string = "";
    departmentMsbId: string = "";

    paymentType: keyof typeof PaymentType = PaymentType[PaymentType.Unknown] as keyof typeof PaymentType;
    isCardPresent: boolean = false; // false for quick pay/express payments

    isMailOrTelephoneOrder: boolean = false; // for constituents set true

    nameOnCard: string = "";
    cardType: PaymentCardTypeEnum = PaymentCardTypeEnum.Unknown;
    creditCardNumber: string = "";
    expirationDate: string = "";
    cvv: string = "";

    eCheckRoutingNumber: string = "";
    eCheckAccountNumber: string = "";

    productType: string = "";

    addressLine1: string = "";
    addressLine2: string = "";
    country: string = "";
    city: string = "";
    state: string = "";
    zip: string = "";

    phone: string = "";
    emailAddress: string = "";

    amount: number = 0;
    convenienceFee: number = 0;
    totalAmount: number = 0;

    orderLines: Array<OrderLine> = new Array<OrderLine>();
    laneId: number = 0;
    terminalId: string = "";

    orderIdentifier?: string;
    reason?: string;
    chargebackDueDate?: string;
    previousTransactionId?: string;
    vantivTransactionRefernce?: string;
    metadata: Metadata = new Metadata();

    shouldTokenizeCard: boolean = false;
    paymentMethodOnFileType?: PaymentMethodOnFileType;
    paymentMethodOnFileReference?: string;
    isImplicitAuthorization: boolean = false;
    isTokenisedPayment: boolean = false;
}

export class OrderLine {
    itemReferenceNumber: string = "";
    itemName: string = "";
    unitPrice: number = 0;
    quantity: number = 0;
    itemId: string = "";
}

export class Item {
    msbId?: string;
    name: string = "";
    description: string = "";
    itemType: ItemType = ItemType.Unknown;
    salePrice: number = 0;
    fixedAmount?: number;
    minimumAmount?: number;
    maximumAmount?: number;
    sellingStartDate?: string;
    sellingEnddate?: string;
    externalItemReference?: string;
    internalReferenceNumber?: string;
    isTaxed: boolean = false;
}

export class OrderTransaction {
    email: string = "";
    lastFourOnCard: string = "";
    nameOnCard: string = "";
    orderLines: Array<OrderLine> = new Array<OrderLine>();
    paymentType: string = "";
    phone: string = "";
    initiatedBy?: string;
    transactionType: string = "";
    vantivTransactionReferenceNumber: string = "";
    orderIdentifier: string = "";
    zip: string = "";
    clientMetadata: any = "";
    orderSummary?: any = "";
    reason?: string = "";
}

export class OrderReceipt {
    orderId: string = "";
    clientId: string = "";
    departmentId: string ="";
    paymentChannelId: string = "";
    accountNumbers: string = "";
    amount: string = "";
    applicationIdentifier: string = "";
    approvalNumber: string = "";
    cardLogo: string = "";
    convenienceFee: string = "";
    createdAt: string = "";
    cryptogram: string = "";
    entry: string = "";
    hostResponseCode: string = "";
    merchantIdentifier: string = "";
    nameOnCard: string = "";
    orderTransaction?: OrderTransaction;
    processorType?: string;
    pinVerified: boolean = false;
    terminalIdentifier: string = "";
    signatureBase64: string = "";
    totalAmount: string = "";
    transactionType?: any;
    transactionIdentifier: string = "";
    vantivPaymentType: string = "";
    lastFourOnCard: string = "";
    paymentType: PaymentType = PaymentType.Unknown;
}

export class ClientAddress {
    addressLine1: string = "";
    addressLine2: string = "";
    country: string = "";
    city: string = "";
    state: string = "";
    zip: string = "";
}

export class ProcessorData {
    address?: ClientAddress;
    receipt?: OrderReceipt;
}

export class ProcessedOrder {
    processorData?: ProcessorData;
}

export enum PaymentTypeEnum {
    Unknown = 0,
    CreditCardManual = 1,   //QuickPay & POS
    ECheck = 2,             //QuickPay & POS
    PayPal = 3,             //PayPal
    CreditCardTerminal = 4, //TerminalPay & POS
    PMoF = 5                //PMoF & POS
}

//Refactor to use only one payment type
export enum ReportsPaymentType{
    allPayments = 1,
    achPayments = 2,
    cash = 3,
    check = 4,
    creditCard = 5,
    moneyOrder = 6
}

export enum TransactionTypeEnum {
    Unknown = 0,
    Sale = 1,
    Void = 2,
    Refund = 3,
    Reversal = 4,
    Return = 5,
    Authorization = 6,
    AuthorizationCommit = 7,
    CreateToken = 8,
    Chargeback = 9,
    ChargebackReversal = 10
}

export enum OrderStatus{
    Unknown = 0, 
    Pending = 1,
    Success = 2,
    Declined = 3,
    Error = 4,
    Overridden = 5
}

export class PaymentTransactions {
    msbId?: string = "";
    departmentId: string = "";
    customerName: string = "";
    orderIdentifier: string = "";
    nameOnCard: string = "";
    status: string = "";
    totalAmount: number = 0
    transactionDate: string = "";
    transactionId: string = "";
    transactionReference: string = "";
    transactionType: string = "";
    paymentType: string = "";
}

export class FeeCalculation {
    amount: string = '';
    convenienceFee: string = '';
    totalAmount: string = '';
}

export class Receipt {
    address?: ClientAddress;
    receipt?: ReceiptDetails;
}

export class ReceiptDetails{
    cardLogo: string = "";
    orderId: string = "";
    orderTransactionId: string = "";
    createdAt: string = "";
    transactionType: number = 0;
    lastFourOnCard: string = "";
    paymentDate: string = "";
    paymentType: number = 0;
    paymentMethod: string = "";
    paymentAccount: string = "";
    paymentChannel : string = "";
    customerName: string = "";
    nameOnCard: string = "";
    paymentChannelId: string = "";
    referenceNumber: string = "";
    amount: string = "";
    convenienceFee : string = "";
    totalAmount : string = "";
    orderTransaction: any;
    transactionIdentifier: string ="";
    processorType: string = "";
    approvalNumber: string = "";
    cryptogram: string = "";
    entry: string = "";
    merchantIdentifier: string = "";
    pinVerified: boolean = false;
    hostResponseCode: string = "";
    signatureBase64: string = "";
    terminalIdentifier: string = "";
}

export class TransactionDetails {
    clientId: string = "";
    departmentId: string = "";
    paymentChannelId: string = "";
    additionalValues: any;
    addressLine1: string = "";
    addressLine2: string = "";
    amount: number = 0;
    city: string = "";
    orderIdentifier: string= "";
    convenienceFee: number = 0;
    email: string = "";
    customerName: string = "";
    phone: string = "";
    initiatedBy: string = "";
    createdAt: string = "";
    orderStatus: string = "";
    paymentAccount: string = "";
    paymentChannel: string = "";
    paymentDate: string = "";
    paymentType: string = "";
    relatedTransaction: any;
    state: string = "";
    totalAmount: number = 0;
    originalAmount: number = 0;
    remainingBalance: number = 0;
    transactionDate: string = "";
    status : string= "";
    nameOnCard : string = "";
    transactionId: string = "";
    transactionIdentifier: string = "";
    transactionReference: string = "";
    transactionState: string = "";
    transactionType: string = "";
    vantivTransactionReferenceNumber: string="";
    zip: string = "";
    orderPayment?: OrderPayment;
    orderLines?: OrderLine;
    lastFourOnCard?: string;
    orderSummary?: OrderSummary;
    processorMessage?: string;
    convenienceFeeIsClientAbsorbed?: boolean;
    clientMetadata?: any;
}
export class BreadCrumbDetails {
    reportTitle: string = "";
    reportRoute: string = ""
}
export class AvailablePaymentChannels {
    paymentChannel: string = ""
    paymentChannelId: string = "";
}

export class DualList {
    label: string = ""
    value: string = "";
}

export class SearchList {
    lastFourOnCard?: string;
    confirmationCode?: string;
    customerName?: string;
    endAmount?: number;
    endDate?: string;
    endTime?: string;
    initiatedBy?: string;
    paymentAccount?: string;
    paymentChannels?: string[];
    paymentType?: string;
    paymentTypeSelect?: any[];
    startAmount?: number;
    startDate?: string;
    startTime?: string;
    terminalName?: string;
    orderIdentifier?: string;
    vantivTransactionReferenceNumber?: string;
    createdAt?: string;
    customSelectComponent?: any[];
    transactionType?: string;
    cardLogo?: string;
    paymentChannelId?: string;
    nameOnCard?: string;
    itemReferenceNumber?: string;
    totalAmount?: string;
    departmentId?: string;
    departmentIds?: string[];
    clientIds? : string;
    accountDDATypeEnum? : AccountDDATypeEnum;
    bankAccountNumber?: string;
    bankRoutingNumber?: string;
    itemReferenceToggle? : any
}


export class ReceiptWrapper{
    printerId:number = 0;
    printerName:string = "";
    orderPayment?:   OrderReceipt;
    receiptAddress?: ClientAddress;
}

export class defaultColumns {
    clientName: string = "";
    departmentName: string = "";
    paymentChannelName: string = "";
    initiatedBy: string = "";
    orderIdentifier: string = "";
    lastFourOnCard: string = "";
    itemReference: string = "";
    itemName: string = "";
    reason: string = "";
    createdAt: string = "";
    transactionType: string = "";
    amount: string = "";
    convenienceFee: string = "";
    totalAmount: string = "";
    originalAmount: string = "";
    remainingBalance: string = "";
    nameOnCard: string = "";
    cardType: string = "";
    feeAssessedCardType: string = "";
    phone: string = "";
    email: string = "";
    addressLine1: string = "";
    addressLine2: string = "";
    city: string = "";
    state: string = "";
    zip: string = "";
    totalTransactions?:any;
    reportClientMetaDataFields?: any;
}

export class financeACHColumns {
    clientName: string = "";
    clientTimeZone: string = "";
    departmentName: string = "";
    paymentChannelName: string = "";
    initiatedBy: string = "";
    orderIdentifier: string = "";
    lastFourOnCard: string = "";
    itemReference: string = "";
    itemName: string = "";
    reason: string = "";
    viewersTimezone: string = "";
    clientTimezone: string = "";
    centralTimezone: string = "";
    transactionType: string = "";
    amount: string = "";
    convenienceFee: string = "";
    totalAmount: string = "";
    originalAmount: string = "";
    remainingBalance: string = "";
    nameOnCard: string = "";
    cardType: string = "";
    phone: string = "";
    email: string = "";
    addressLine1: string = "";
    addressLine2: string = "";
    city: string = "";
    state: string = "";
    zip: string = "";
    totalTransactions?:any;
}
import { User, UserTypeEnum } from '../models/User';

export const formatDynamicSearchParameters = (key: any, value: any, currentUser?: User, searchValues?:any) => {

    const isClientUser = (currentUser?.userTypeEnum === UserTypeEnum.Client);

    const specialParameters = (key: any, value: any, isClientUser?: boolean) => {

        const containsSeparator = value.toString().includes("|")
        const modifiedParameterString = (key == "departmentId" && isClientUser) ? ":xor"
            : (containsSeparator && key == "departmentId" && !isClientUser) || (containsSeparator && key == "transactionType") ? ":or"
                : ''

        return modifiedParameterString
    }

    const parametersMap: any = {
        "createdAt": ":from",
        "totalAmount": ":from",
        "nameOnCard": ":like",
        "initiatedBy": ":like",
        "departmentId": specialParameters(key, value, isClientUser),
        "transactionType": specialParameters(key, value),
        "itemReferenceNumber": searchValues?.itemReferenceToggle ? ":like" : ""
    }

    if (key === "itemReferenceToggle") {
        return ''; 
    }

    const formattedParameter = parametersMap[key] ? `${key}${parametersMap[key]}` : `${key}`

    return formattedParameter
};


